import {Injectable, Type} from "@angular/core";
import {LoggerService} from "@services/logger-service.service";
import {BehaviorSubject, Observable} from "rxjs";
import {DestroyComponentData, DynamicComponent, DynamicComponentsContainerData, DynamicComponentsContainers} from "./dynamic-component.model";

@Injectable({
  providedIn: "root"
})
export class DynamicComponentsService {
  private containers: DynamicComponentsContainers = {};

  private componentsContainerSource: BehaviorSubject<DynamicComponentsContainerData> = new BehaviorSubject<DynamicComponentsContainerData>(null);
  private destroyComponentsSource: BehaviorSubject<DestroyComponentData> = new BehaviorSubject<DestroyComponentData>(null);

  public componentsContainer$: Observable<DynamicComponentsContainerData> = this.componentsContainerSource.asObservable();
  public destroyComponents$: Observable<DestroyComponentData> = this.destroyComponentsSource.asObservable();

  constructor(private logger: LoggerService) {
    this.logger.debug(this.constructor.name + " is created.");
  }

  public addComponent(containerName: string, componentType: Type<unknown>, data?: Record<string, unknown>, id?: string): string {
    const componentId: string = id || crypto.randomUUID();
    const components: DynamicComponent[] = this.getContainerComponents(containerName);
    const component: DynamicComponent = components.find((dynamicComponent: DynamicComponent) => dynamicComponent.id === id);
    if (component) {
      component.componentType = componentType;
      component.data = data;
    } else {
      components.push(new DynamicComponent(componentId, componentType, data));
    }
    this.updateContainerComponents(containerName, components);
    return componentId;
  }

  public destroyComponent(containerName: string, id: string): void {
    const components: DynamicComponent[] = this.getContainerComponents(containerName);
    this.destroyComponentsSource.next({containerName, id});
    this.containers[containerName] = components.filter((component) => component.id !== id);
  }

  public clearComponents(containerName: string): void {
    this.updateContainerComponents(containerName);
  }

  private getContainerComponents(containerName: string): DynamicComponent[] {
    return this.containers[containerName] ?? [];
  }

  private updateContainerComponents(containerName: string, components: DynamicComponent[] = []): void {
    this.containers[containerName] = components;
    this.componentsContainerSource.next({containerName, components});
  }
}
