import { Type } from "@angular/core";

export class DynamicComponent {
  constructor(public id: string, public componentType: Type<any>, public data: any) {}
}

export interface DynamicComponentsContainerData {
  containerName: string;
  components: DynamicComponent[]
}

export type DynamicComponentsContainers = Record<string, DynamicComponent[]>;

export type DestroyComponentData = {
  containerName: string;
  id: string;
}
